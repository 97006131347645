<template>
  <div>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <dispatch-list type="partner" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'

import store from '@/store/index'

import { mapActions, mapGetters } from 'vuex'
import EcommerceStatistics from './EcommerceStatistics.vue'
import DispatchList from './DispatchList.vue'

export default {
  components: {
    BRow,
    BCol,
    EcommerceStatistics,
    DispatchList,
  },
  data() {
    return {
      data: {
        statisticsItems: [
          {
            icon: 'BriefcaseIcon',
            color: 'light-primary',
            title: '230k',
            subtitle: 'Wallet',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'UsersIcon',
            color: 'light-info',
            title: '8.549k',
            subtitle: 'Riders',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'TruckIcon',
            color: 'light-danger',
            title: '1.423k',
            subtitle: 'Dispatches',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'BoxIcon',
            color: 'light-warning',
            title: '1.423k',
            subtitle: 'DAAS',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'CreditCardIcon',
            color: 'light-success',
            title: '$9745',
            subtitle: 'Accounts',
            customClass: '',
          },
        ],
        companyTable: [
          {
            // eslint-disable-next-line global-require
            avatarImg: require('@/assets/images/icons/toolbox.svg'),
            title: 'Dixons',
            subtitle: 'meguc@ruj.io',
            avatarIcon: 'MonitorIcon',
            avatarColor: 'light-primary',
            avatarTitle: 'Technology',
            viewTitle: '23.4k',
            viewsub: 'in 24 hours',
            revenue: '891.2',
            sales: '68',
            loss: true,
          },
          {
            // eslint-disable-next-line global-require
            avatarImg: require('@/assets/images/icons/parachute.svg'),
            title: 'Motels',
            subtitle: 'vecav@hodzi.co.uk',
            avatarIcon: 'CoffeeIcon',
            avatarColor: 'light-success',
            avatarTitle: 'Grocery',
            viewTitle: '78k',
            viewsub: 'in 2 days',
            revenue: '668.51',
            sales: '97',
            loss: false,
          },
          {
            // eslint-disable-next-line global-require
            avatarImg: require('@/assets/images/icons/brush.svg'),
            title: 'Zipcar',
            subtitle: 'davcilse@is.gov',
            avatarIcon: 'WatchIcon',
            avatarColor: 'light-warning',
            avatarTitle: 'Fashion',
            viewTitle: '162',
            viewsub: 'in 5 days',
            revenue: '522.29',
            sales: '62',
            loss: false,
          },
          {
            // eslint-disable-next-line global-require
            avatarImg: require('@/assets/images/icons/star.svg'),
            title: 'Owning',
            subtitle: 'us@cuhil.gov',
            avatarIcon: 'MonitorIcon',
            avatarColor: 'light-primary',
            avatarTitle: 'Technology',
            viewTitle: '214',
            viewsub: 'in 24 hours',
            revenue: '291.01',
            sales: '88',
            loss: false,
          },
          {
            // eslint-disable-next-line global-require
            avatarImg: require('@/assets/images/icons/book.svg'),
            title: 'Cafés',
            subtitle: 'pudais@jife.com',
            avatarIcon: 'CoffeeIcon',
            avatarColor: 'light-success',
            avatarTitle: 'Grocery',
            viewTitle: '208',
            viewsub: 'in 1 week',
            revenue: '783.93',
            sales: '16',
            loss: true,
          },
          {
            // eslint-disable-next-line global-require
            avatarImg: require('@/assets/images/icons/rocket.svg'),
            title: 'Kmart',
            subtitle: 'bipri@cawiw.com',
            avatarIcon: 'WatchIcon',
            avatarColor: 'light-warning',
            avatarTitle: 'Fashion',
            viewTitle: '990',
            viewsub: 'in 1 month',
            revenue: '780.05',
            sales: '78',
            loss: false,
          },
          {
            // eslint-disable-next-line global-require
            avatarImg: require('@/assets/images/icons/speaker.svg'),
            title: 'Payers',
            subtitle: 'luk@izug.io',
            avatarIcon: 'WatchIcon',
            avatarColor: 'light-warning',
            avatarTitle: 'Fashion',
            viewTitle: '12.9k',
            viewsub: 'in 12 hours',
            revenue: '531.49',
            sales: '42',
            loss: false,
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'riderAdded', 'accountAdded', 'payoutAdded']),
  },
  watch: {
    currentUser(val) {
      if (val && val.token && val.token.length > 0) this.updateData()
    },
    riderAdded(val) {
      if (val && val === true) {
        this.profile({ token: store.state.user.currentUser.token })
        store.state.user.riderAdded = false
      }
    },
    accountAdded(val) {
      if (val && val === true) {
        this.profile({ token: store.state.user.currentUser.token })
        store.state.user.accountAdded = false
      }
    },
    payoutAdded(val) {
      if (val && val === true) {
        this.profile({ token: store.state.user.currentUser.token })
        store.state.user.payoutAdded = false
      }
    },
  },
  created() {
    this.updateData()
  },
  mounted() {
    this.profile({ token: store.state.user.currentUser.token })
  },
  methods: {
    ...mapActions(['profile']),
    updateData() {
      const { userType, user } = store.state.user.currentUser
      const partner = userType === 'partner' ? user : user.partner
      const {
        balance, ridersCount, dispatchesCount, daasCount, bankAccountsCount,
      } = partner

      const statisticsItems = {
        balance: this.humanize(balance ?? 0),
        ridersCount,
        dispatchesCount,
        daasCount,
        bankAccountsCount,
      }

      Object.keys(statisticsItems).forEach((key, index) => {
        this.data.statisticsItems[index].title = statisticsItems[key]
      })
    },

    // humanize(value) {
    //   // Nine Zeroes for Billions
    //   return Math.abs(Number(value)) >= 1.0e9
    //     ? `₦${(Math.abs(Number(value)) / 1.0e9).toFixed(2)}B`
    //     : // Six Zeroes for Millions
    //     Math.abs(Number(value)) >= 1.0e6
    //       ? `₦${(Math.abs(Number(value)) / 1.0e6).toFixed(2)}M`
    //       : // Three Zeroes for Thousands
    //       Math.abs(Number(value)) >= 1.0e3
    //         ? `₦${(Math.abs(Number(value)) / 1.0e3).toFixed(2)}K`
    //         : `₦${Math.abs(Number(value))}`
    // },
    humanize(value) {
      const absValue = Math.abs(Number(value))

      if (absValue >= 1.0e9) {
        return `₦${(absValue / 1.0e9).toFixed(2)}B`
      } if (absValue >= 1.0e6) {
        return `₦${(absValue / 1.0e6).toFixed(2)}M`
      } if (absValue >= 1.0e3) {
        return `₦${(absValue / 1.0e3).toFixed(2)}K`
      }

      return `₦${absValue}`
    },
  },
}
</script>

<style></style>
